import style from './SideMenuBtn.module.css';

import { AiFillMedicineBox, AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoHome, IoPersonAddSharp, IoDocumentText } from "react-icons/io5";
import { FaMapMarkerAlt, FaDollarSign } from "react-icons/fa";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { RiFileExcel2Line, RiHealthBookFill } from "react-icons/ri";
import { BiSolidHelpCircle, BiTransfer } from "react-icons/bi";
import { GiHealthNormal } from "react-icons/gi";
import { IoMdAdd, IoMdClose, IoMdCheckmark } from "react-icons/io";
import { BsListCheck } from "react-icons/bs";


const iconMap = {
    'AFASTAMENTOS' : RiHealthBookFill,
    'ATENDIMENTOS' : BiSolidHelpCircle,
    'EXAMES OCUPACIONAIS' : GiHealthNormal,
    'FOLGA DE CAMPO' : FaMapMarkerAlt,
    'FOLHA DE PAGAMENTO' : FaMoneyCheckDollar,
    'GERAR RELATÓRIO' : RiFileExcel2Line,
    'PROCESSO ADM/TRANSF' : IoPersonAddSharp,
    'CONTROLE DE EXPERIÊNCIA' : IoDocumentText,
    'REPUBLICAS' : IoHome,
    'NOVA REPUBLICA' : IoHome,

    'SOLICITAR ASO': AiFillMedicineBox,
    'BENEFÍCIOS': FaDollarSign,
    'NOVO COLABORADOR': IoPersonAddSharp,
    'TRANSFERIR COLABORADOR': BiTransfer,
    'NOVA AUDITORIA': IoMdAdd,
    'REGISTRAR NOVO ITEM': IoMdAdd,
    'PLANEJAR SETOR': IoMdAdd,
    "ITENS PENDENTES": BsListCheck,
    "CANCELAR AUDITORIA": IoMdClose,
    "FINALIZAR AUDITORIA": IoMdCheckmark
}

const SideMenuBtn = ({ button, clickFunc, isLoading }) => {

    const IconComponent = iconMap[ button ]

    return(
        <div
            className={ style.sideMenuBtn }
            onClick={ !isLoading ? clickFunc : undefined }
            role='button'
        >

            <div className={ style.divIcon } >
                { isLoading ? <AiOutlineLoading3Quarters className={ style.spinner } /> : <IconComponent /> }
            </div>
            <p>{ button }</p>
        </div>
    )

}

export default SideMenuBtn;