import { useEffect, useState, useCallback } from "react";
import AuditIntens from "../Componentes/AuditInterna/AuditItens";
import NavBar from "../Componentes/NavBar";
import Overlay from "../Componentes/Overlay";
import { useParams } from "react-router-dom";
import axios from "axios";
import SideMenu from "../Componentes/SideMenu";
import SideMenuBtn from "../Componentes/SideMenuBtn";
import AddNewItem from "../Componentes/AuditInterna/AddNewItem";
import { ToastContainer } from 'react-toastify';
import EditItem from "../Componentes/AuditInterna/EditItem";
import CancelSquare from "../Componentes/AuditInterna/CanecelSquare";
import FinishSquare from "../Componentes/AuditInterna/FinishSquare";

import { saveAs } from "file-saver";

const AuditoriaInternaId = () => {

    const { id } = useParams();

    const [auditInfo, setAuditInfo] = useState({
        contrato: '',
        data: '',
        status: '',
        itensC: 0,
        itensNC: 0,
        itensNA: 0,
        itensOM: 0,
        percent: ''
    })

    const [itensAudit, setItensAudit] = useState([])
    const [itensAuditFilter, setItensAuditFilter] = useState([])

    const fetchAuditItens = async () => {

        try {

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/AuditInterna/GetAuditItens/', {
                id: id
            })

            console.log(data)

            setAuditInfo({
                contrato: data.contrato,
                data: data.data_prev_inicio,
                status: data.status,
                itensC: data.itens_c,
                itensNC: data.itens_nc,
                itensNA: data.itens_na,
                itensOM: data.itens_om,
                percent: data.percent
            })

            setItensAudit(data.itens);
            setItensAuditFilter(data.itens);

        } catch (error) {

            console.error(error);

        }

    }

    useEffect(() => {

        fetchAuditItens();

    }, [])

    const handleFilter = useCallback(( normaFilter, itemFilter, auditorFilter, auditadoFilter, setoresFilter, confFilter ) => {

        const filtered = itensAudit.filter( item => {

            const norma = normaFilter.length === 0 || normaFilter.some( obj => item.norma.includes( obj.value ))
            const subitem = itemFilter.length === 0 || itemFilter.some( obj => item.subitem.includes( obj.value ))
            const auditor = auditorFilter.length === 0 || auditorFilter.some( obj => item.auditor.includes( obj.value ))
            const auditado = auditadoFilter.length === 0 || auditadoFilter.some( obj => item.auditado.includes( obj.value ))
            const setor = setoresFilter.length === 0 || setoresFilter.some( obj => item.setor === obj.value )
            const conformidade = confFilter.length === 0 || confFilter.some( obj => item.conformidade === obj.value )

            return norma && subitem && auditor && auditado && setor && conformidade;

        })

        setItensAuditFilter( filtered )

    })

    const [sideOverlay, setSideOverlay] = useState(false);
    const [formAddOverlay, setFormAddOverlay] = useState(false);
    const [formEditOverlay, setFormEditOverlay] = useState(false);

    const [cancelOverlay, setCancelOverlay] = useState(false);
    const [finishOverlay, setFinishOverlay] = useState(false); 

    const openSideBar = () => {

        setSideOverlay(true);

    }

    const closeSideBar = () => {

        setSideOverlay(false);

    }

    const openForm = () => {

        setSideOverlay(false);
        setFormAddOverlay(true);

    }
    const closeForm = () => {
        setFormAddOverlay(false);
    }

    const openCheckList = () => {

        window.open(`/AuditoriaCheck/Id/${id}`)

    }

    const openCancel = () => {

        setSideOverlay(false);
        setCancelOverlay(true);

    }

    const closeCancel = () => {

        setCancelOverlay(false);

    }

    const openFinish = () => {

        setSideOverlay( false )
        setFinishOverlay( true )

    }

    const closeFinish = () => {

        setFinishOverlay( false )

    }

    const [itemEdit, setItemEdit] = useState(null)

    const openItemEdit = (item) => {

        setItemEdit(item);
        setFormEditOverlay(true);

        console.log(item)

    }

    const closeEdit = () => {

        setItemEdit(null);
        setFormEditOverlay(false);

    }

    const [ loadRelat, setLoadRelat ] = useState( false );

    const downloadRelat = async () => {

        setLoadRelat(true);

        try{

            const { data } = await axios({
                url: 'https://sistemaintegrado.palmont.com.br/Painel/API/AuditInterna/DownloadRelatorio/',
                method: 'POST',
                responseType: 'blob',
                data: {
                    itens: itensAuditFilter,
                    id: id    
                }
            })

            const blob = new Blob([data], { type: 'application/pdf' })
            saveAs( blob, 'arquivo.pdf' )

        }catch( error ){

            console.log( error )

        }finally{

            setLoadRelat(false);

        }

    }

    return (
        <>
            <NavBar
                title={"ITENS AUDITORIA - " + auditInfo.contrato + " | " + auditInfo.data}
                changeState={openSideBar}
            />
            <AuditIntens
                itens={itensAuditFilter}
                itensToFilter={itensAudit}
                percentage={auditInfo.percent}
                openEdit={openItemEdit}
                handleFilter={handleFilter}
            />
            {
                sideOverlay && (
                    <Overlay
                        state={true}
                        changeContent={closeSideBar}
                    >
                        <SideMenu>
                            {(auditInfo.status !== 'CANCELADO' && auditInfo.status !== 'FINALIZADO') && (
                                <SideMenuBtn
                                    button="REGISTRAR NOVO ITEM"
                                    clickFunc={openForm}
                                />
                            )}

                            <SideMenuBtn
                                button="ITENS PENDENTES"
                                clickFunc={openCheckList}
                            />

                            <SideMenuBtn
                                button="CANCELAR AUDITORIA"
                                clickFunc={openCancel}
                            />
                            <SideMenuBtn
                                button="FINALIZAR AUDITORIA"
                                clickFunc={openFinish}
                            />
                            <SideMenuBtn
                                button="GERAR RELATÓRIO"
                                isLoading={ loadRelat }
                                clickFunc={downloadRelat}
                            />
                        </SideMenu>
                    </Overlay>
                )
            }
            {
                formAddOverlay && (
                    <Overlay
                        state={true}
                    >
                        <AddNewItem
                            close={closeForm}
                            auditoria={id}
                            updateItens={fetchAuditItens}
                        />
                    </Overlay>
                )
            }
            {
                formEditOverlay && (
                    <Overlay state={true} >
                        <EditItem
                            auditoria={id}
                            itemSelected={itemEdit}
                            closeEdit={closeEdit}
                            updateItens={fetchAuditItens}
                        />
                    </Overlay>
                )
            }
            {
                cancelOverlay && (
                    <Overlay state={true} >
                        <CancelSquare
                            close={closeCancel}
                            updateAudit={ fetchAuditItens }
                        />
                    </Overlay>
                )
            }
            {
                finishOverlay && (
                    <Overlay state={ true } >
                        <FinishSquare
                            close={ closeFinish }
                            updateAudit={ fetchAuditItens }
                        />
                    </Overlay>
                )
            }
            <ToastContainer
                style={{
                    zIndex: 99999
                }}
                autoClose={3000}
                position="top-center"
                theme="dark"
            />
        </>
    )

}

export default AuditoriaInternaId;