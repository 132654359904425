import style from './FormPlanej.module.css';

import FormComp from '../../FormComp'

import axios from 'axios';
import Cookies from "js-cookie"
import { IoMdClose } from "react-icons/io";
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const FormPlanej = ({ closeForm, updatePlanej, selectedItem }) => {

    const { id } = useParams();
    const token = Cookies.get('token')

    const [ formValues, setFormValues ] = useState({
        setor: '',
        data_planej: '',
        auditor: '',
        auditado: '',
        identifier: id
    })

    const setores = [
        "ADM",
        "ALMOXARIFADO/MATERIAIS",
        "ALTA DIREÇÃO",
        "ANDAIMES",
        "COMERCIAL",
        "CONTABILIDADE",
        "CONTROLE DE OBRAS",
        "ELÉTRICA",
        "ENGENHARIA E ORÇAMENTOS",
        "FABRICAÇÃO (PIPE SHOP)",
        "FINANCEIRO",
        "FISCAL",
        "IÇAMENTO E MOVIMENTAÇÃO DE CARGA",
        "INSTRUMENTAÇÃO",
        "ISOLAMENTO TÉRMICO",
        "JURÍDICO",
        "LOGÍSTICA",
        "MANUTENÇÃO MECÂNICA",
        "MECÂNICA",
        "PINTURA",
        "PLANEJAMENTO/GERENCIAMENTO DE OBRAS",
        "QUALIDADE",
        "RECURSOS HUMANOS",
        "SGI - SISTEMA DE GESTÃO INTEGRADO",
        "SSMA",
        "SUPRIMENTOS",
        "T.I.",
        "TUBULAÇÃO"
    ]

    const auditores = [
        "AMANDA BARCELLOS",
        "RODRIGO RIQUELME",
        "ROGERIO GARCIA",
        "VICTOR PEDRO"
    ]

    const [ lideres, setLideres ] = useState([])

    const getLideres = async () => {

        try{

            const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/assets/php/getLideres.php')
            
            const lideresList = data.map( obj => obj.RA_NOMECMP )
            setLideres( lideresList )
            
        }catch( error ){

            console.log( error )

        }

    }

    const getPlanejInfo = async () => {

        try{

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/AuditInterna/GetPlanejInfo/', {
                id: selectedItem
            })

            setFormValues({
                setor: data.setor,
                data_planej: data.data_planej,
                auditor: data.auditor,
                auditado: data.auditado,
                identifier: data.identifier,
                id: data.id
            })

        }catch( error ){

            console.log( error.message )

        }

    }

    useEffect(() => {

        if( selectedItem ){

            console.log( selectedItem )
            getPlanejInfo();

        }

        getLideres();

    }, [ selectedItem ])

    const handleChange = ( valor, name ) => {

        setFormValues( prev => ({

            ...prev,
            [ name ]: valor

        }))

    }

    const handleSubmit = async ( e ) => {

        e.preventDefault()

        let endpointApi = selectedItem ? 'https://sistemaintegrado.palmont.com.br/Painel/API/AuditInterna/EditPlanej/' : 'https://sistemaintegrado.palmont.com.br/Painel/API/AuditInterna/SaveNewPlanej/'
        
        try{

            const { data } = await toast.promise(
                axios.post(
                    endpointApi,
                    formValues,
                    {
                        headers:{
                            'Authorization': `Bearer ${ token }`
                        }
                    }
                ),
                {
                    pending: 'SALVANDO INFORMAÇÕES',
                    success: 'INFORMAÇÕES SALVAS COM SUCESSO',
                    error: 'FALHA AO SALVAR AS INFORMAÇÕES'
                }
            )

            await updatePlanej();
            closeForm();

        }catch( error ){

            console.error( error )
            toast.error( error.response.data.error );

        }

    }

    return (
        <div className={ style.container } >
            <div className={ style.header } >
                <div className={ style.closeBox } >
                    <IoMdClose
                        className={ style.closeIcon }
                        onClick={ () => closeForm() }
                    />
                </div>
            </div>
            <div className={ style.body } >
                <form
                    className={ style.formulario }
                    onSubmit={ handleSubmit }
                >
                    <FormComp
                        title="SETOR"
                        type="select"
                        options={ setores }
                        required
                        valor={ formValues.setor }
                        changeFunc={ ( e ) => handleChange( e.target.value, 'setor' ) }
                    />
                    <FormComp
                        title="DATA PLANEJADA"
                        type="datetime-local"
                        required
                        valor={ formValues.data_planej }
                        changeFunc={ ( e ) => handleChange( e.target.value, 'data_planej' ) }
                    />
                    <FormComp
                        title="AUDITOR"
                        type="select"
                        options={ auditores }
                        required
                        valor={ formValues.auditor }
                        changeFunc={ ( e ) => handleChange( e.target.value, 'auditor' ) }
                    />
                    <FormComp
                        title="AUDITADO"
                        type="select"
                        options={ lideres }
                        required
                        valor={ formValues.auditado }
                        changeFunc={ ( e ) => handleChange( e.target.value, 'auditado' ) }
                    />
                    <FormComp
                        type="submit"
                        valor="SALVAR"
                    />
                </form>
            </div>
        </div>
    )

}

export default FormPlanej;