import NavBar from "../Componentes/NavBar";

const RAC = () => {

    return(
        <>
            <NavBar
                title="RAC's"
            />
        </>
    )

}

export default RAC;