import CheckItensBody from "../Componentes/AuditInterna/CheckItensBody";
import NavBar from "../Componentes/NavBar";

const AuditoriaCheck = () => {

    return(
        <>
            <NavBar
                title="ITENS PENDENTES AUDITORIA"
            />
            <CheckItensBody/>
        </>
    )

}

export default AuditoriaCheck;