import axios from "axios";
import AuditBody from "../Componentes/AuditInterna/AuditBody";
import NavBar from "../Componentes/NavBar";
import Overlay from "../Componentes/Overlay";
import SideMenu from "../Componentes/SideMenu";
import SideMenuBtn from "../Componentes/SideMenuBtn";
import AuditForm from "../Componentes/AuditInterna/AuditForm";
import ReprogForm from "../Componentes/AuditInterna/ReprogForm";

import { useCallback, useEffect, useState } from "react";
import { ToastContainer } from 'react-toastify';
import ViewEdits from "../Componentes/AuditInterna/ViewEdits";

const AuditoriaInterna = () => {

    const [auditorias, setAuditorias] = useState([])
    const [filterAuditorias, setFilterAuditorias] = useState([])

    const [sideOverlay, setSideOverlay] = useState(false)
    const [addOverlay, setAddOverlay] = useState(false)
    const [editOverlay, setEditOverlay] = useState(false)
    const [editId, setEditId] = useState( null )
    const [viewEdit, setViewEdit] = useState( false )
    const [viewId, setViewId] = useState( null )

    const openSideOverlay = () => {

        setSideOverlay(true)

    }

    const closeSideOverlay = () => {

        setSideOverlay(false)

    }

    const openAddOverlay = () => {

        setSideOverlay(false)
        setAddOverlay(true)

    }

    const closeAddOverlay = () => {

        setAddOverlay(false)

    }

    const fetchAudit = async () => {

        try {

            const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/AuditInterna/')
            console.log(data)

            setAuditorias(data)
            setFilterAuditorias(data)

        } catch (error) {

            console.error(error)

        }

    }

    useEffect(() => {

        fetchAudit();

    }, [])

    const openEdit = ( id ) => {

        setEditId( id )
        setEditOverlay( true );

    }

    const closeEdit = () => {

        setEditOverlay( false );
        setEditId( null );

    }

    const openView = ( id ) => {

        setViewId( id );
        setViewEdit( true );

        console.log( id )

    }

    const closeView = () => {

        setViewId( null );
        setViewEdit( false );

    }

    const handleFilter = useCallback (( obraFilter, contratoFilter, statusFilter, auditorFilter, startDateFilter, endDateFilter ) => {

        const filtered = auditorias.filter( item => {


            const obra_filter = obraFilter.length === 0 || obraFilter.some( obj => item.centro_custo?.includes( obj.value ))
            const contrato_filter = contratoFilter.length === 0 || contratoFilter.some( obj => item.contrato?.includes( obj.value ))
            const status_filter = statusFilter.length === 0 || statusFilter.some( obj => item.status?.includes( obj.value ))
            const auditor_filter = auditorFilter.length === 0 || auditorFilter.some( obj => item.auditores?.includes( obj.value ))

            const start_date_filter = !startDateFilter || new Date( item.data_prev_inicio ) >= new Date( startDateFilter );
            const end_date_filter = !endDateFilter || new Date( item.data_prev_fim ) <= new Date( endDateFilter );

            return obra_filter && contrato_filter && status_filter && auditor_filter && start_date_filter && end_date_filter;

        })

        setFilterAuditorias( filtered )

    }, [ auditorias ])

    return (
        <>
            <NavBar
                title="AUDITORIA INTERNA"
                setSideBar={openSideOverlay}
            />
            <AuditBody
                dados={filterAuditorias}
                dadosToFilter={ auditorias }
                editItem={ openEdit }
                openView={ openView }
                handleFilter={ handleFilter }
            />

            {
                sideOverlay && (
                    <Overlay
                        state={true}
                        changeContent={closeSideOverlay}
                    >
                        <SideMenu>
                            <SideMenuBtn
                                button="NOVA AUDITORIA"
                                clickFunc={openAddOverlay}
                            />
                        </SideMenu>
                    </Overlay>
                )
            }

            {
                addOverlay && (
                    <Overlay state={true} >
                        <AuditForm
                            close={closeAddOverlay}
                            updateItens={fetchAudit}
                        />
                    </Overlay>
                )
            }

            {
                editOverlay && (
                    <Overlay state={true} >
                        <ReprogForm
                            id={ editId }
                            closeForm={ closeEdit }
                            updateItens={ fetchAudit }
                        />
                    </Overlay>
                )
            }

            {
                viewEdit && (
                    <Overlay state={ true } >
                        <ViewEdits
                            closeFunc={ closeView }
                            id={ viewId }
                        />
                    </Overlay>
                )
            }

            <ToastContainer
                style={{
                    zIndex: 99999
                }}
                autoClose={3000}
                position="top-center"
                theme="dark"
            />

        </>
    )

}

export default AuditoriaInterna;