import './NavBar.css'

import { MdMenu } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

const NavBar = ({ state, changeState, setSideBar, title, prevDest }) => {

    const navigate = useNavigate();

    const previousDestination = prevDest ? '/' + prevDest : '/Home'

    let titulo = ''

    if( title ){

        titulo = title

    }

    const handleClick = () => {

        if( changeState ){

            changeState( !state );

        }

        if( setSideBar ){

            setSideBar();

        }

    }

    return(

        <div className='navbar' >
            <img
                id='Navbar_logo'
                src='/Images/Logo.png'
                alt='Navbar Logo'
                title='Navegar para Home'
                onClick={ () => { navigate( previousDestination ) } }
            />

            <p className='versionText' >P-SIG | PALMONT SISTEMA INTEGRADO DE GESTÃO V.2025.1 - { titulo }</p>

            <MdMenu size={ 40 } id='burgerMenu' onClick={ handleClick } />
        </div>

    )

}

export default NavBar