import style from './AuditForm.module.css';

import FormComp from '../../FormComp'
import { IoMdClose } from "react-icons/io";
import { useEffect, useState } from 'react';
import axios from 'axios';

const AuditForm = ({ close, updateItens }) => {

    const [ formValues, setFormValues ] = useState({
        'obra': '',
        'contrato': '',
        'data_inicial': ''
    })

    const [ obras, setObras ] = useState([])
    const [ contratos, setContratos ] = useState([])

    useEffect(()=>{

        const getObras = async () => {

            try{

                const { data } = await axios.get("https://sistemaintegrado.palmont.com.br/Painel/API/GetObrasAtivas/")
                setObras( data.map( obj => obj.OBRA ) )

            }catch( error ){

                console.log( error )

            }

        }

        getObras();

    }, [])

    useEffect(() => {

        const getContratos = async () => {

            try{

                const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/GetContratosObra/', {

                    centro_custo: formValues.obra

                })

                setFormValues( (prev) => ({

                    ...prev,
                    contrato: ''

                }))
                setContratos( data.map( obj => obj.nome_contrato ) )

            }catch( error ){

                console.log( error )

            }


        }

        getContratos();

    }, [ formValues.obra ])

    useEffect(()=>{

        console.log( formValues )

    },[ formValues ])

    const handleChange = ( valor, name ) => {

        setFormValues( ( prev ) =>  ({

            ...prev,
            [name]: valor

        }))

    }

    const handleSubmit = async ( e ) => {

        e.preventDefault();

        try{

            const response = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/AuditInterna/SaveNewAudti/', formValues );
            console.log( response )

            await updateItens();
            closeForm();

        }catch( error ){

            console.log( error );

        }

    }

    const clearForm = () => {

        setFormValues({
            'obra': '',
            'contrato': '',
            'data_inicial': ''
        })

    }

    const closeForm = () => {

        clearForm();
        close();

    }

    return (
        <div className={style.container} >
            <div className={style.header} >
                <div className={ style.closeBox } >
                    <IoMdClose
                        className={ style.closeIcon }
                        onClick={ closeForm }
                    />
                </div>
            </div>
            <div className={style.body} >
                <form
                    onSubmit={ handleSubmit }
                    className={ style.formulario }
                >
                    <FormComp
                        title="CENTRO DE CUSTO"
                        type="select"
                        options={ obras }
                        valor={ formValues.obra }
                        changeFunc={ ( e ) => handleChange( e.target.value, 'obra' ) }
                        required
                    />
                    <FormComp
                        title="CONTRATO"
                        type="select"
                        options={ contratos }
                        valor={ formValues.contrato }
                        changeFunc={ ( e ) => handleChange( e.target.value, 'contrato' ) }
                        required
                    />
                    <FormComp
                        title="DATA DE INÍCIO"
                        type="date"
                        valor={ formValues.data_inicial }
                        changeFunc={ ( e ) => handleChange( e.target.value, 'data_inicial' ) }
                        required
                    />
                    <FormComp
                        type="submit"
                        valor="SALVAR"
                    />
                </form>
            </div>
        </div>
    )
}

export default AuditForm;