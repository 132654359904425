import style from './AddNewItem.module.css';
import FormComp from '../../FormComp'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getItensByNorma } from '../../../Funcoes/GetItensByNorma';
import { MdOutlineClose } from "react-icons/md";
import { ImSpinner8 } from "react-icons/im";
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';


const AddNewItem = ({ close, auditoria, updateItens }) => {

    const token = Cookies.get('token');

    const [loading, setLoading] = useState(true)

    const [lideres, setLideres] = useState([])
    const setores = [
        "ADM",
        "ALMOXARIFADO/MATERIAIS",
        "ALTA DIREÇÃO",
        "ANDAIMES",
        "COMERCIAL",
        "CONTABILIDADE",
        "CONTROLE DE OBRAS",
        "ELÉTRICA",
        "ENGENHARIA E ORÇAMENTOS",
        "FABRICAÇÃO (PIPE SHOP)",
        "FINANCEIRO",
        "FISCAL",
        "IÇAMENTO E MOVIMENTAÇÃO DE CARGA",
        "INSTRUMENTAÇÃO",
        "ISOLAMENTO TÉRMICO",
        "JURÍDICO",
        "LOGÍSTICA",
        "MANUTENÇÃO MECÂNICA",
        "MECÂNICA",
        "PINTURA",
        "PLANEJAMENTO/GERENCIAMENTO DE OBRAS",
        "QUALIDADE",
        "RECURSOS HUMANOS",
        "SGI - SISTEMA DE GESTÃO INTEGRADO",
        "SSMA",
        "SUPRIMENTOS",
        "T.I.",
        "TUBULAÇÃO"
    ]

    const getLideres = async () => {

        try {

            const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/assets/php/getLideres.php')
            setLideres(data.map(obj => obj.RA_NOMECMP))

        } catch (error) {

            console.log(error)

        }

    }

    useEffect(() => {

        const getInitialValues = async () => {

            await getLideres();
            setLoading( false );

        }

        getInitialValues();

    }, [])

    const [formValues, setFormValues] = useState({
        auditoria: auditoria,
        auditado: '',
        normas: [{ norma: '', item: '' }],
        setor: '',
        conformidade: '',
        fato: '',
        evidencia: ''
    })

    const [ selectedFiles, setFiles ] = useState([])

    const handleChange = (e, index, field) => {

        const { name, value } = e.target

        if (field === 'normas') {

            const updatedNormas = [...formValues.normas]    //Busca os valores atuais dos itens e coloca em uma const
            updatedNormas[index][name] = value            //Seleciona o item que foi chamado de acordo com o index e altera o valor

            if (name === 'norma') {

                updatedNormas[index]["item"] = ''           //Limpa o item caso a norma seja alterada

            }

            setFormValues({
                ...formValues,
                normas: updatedNormas
            })

        } else {

            if ( name === 'conformidade' && ( value === 'C' || value === 'NA' ) ){

                setFormValues({
                    ...formValues,
                    fato: '',
                    [name]: value
                });

            } else {

                setFormValues({
                    ...formValues,
                    [name]: value
                });

            }

        }

    }

    const handleFileChange = (e) => {

        const files = e.target.files
        const arrayFiles = Array.from( files )

        setFiles( arrayFiles )

    }

    const addNormaItem = () => {

        setFormValues({
            ...formValues,
            normas: [...formValues.normas, { norma: '', item: '' }]
        })

    }

    const removeNormaItem = () => {

        const updatedNormas = [...formValues.normas]
        updatedNormas.pop();
        setFormValues({ ...formValues, normas: updatedNormas })

    }

    const [ disabledForm, setDisabledForm ] = useState( false )

    const handleSubmit = async ( e ) => {

        e.preventDefault();
        setDisabledForm( true );

        const formData = new FormData();
        for( const key in formValues ){

            if( key === 'normas' ){

                formData.append( key, JSON.stringify( formValues[ key ] ) )

            }else{

                formData.append( key, formValues[ key ] );

            }

        }

        if( selectedFiles ){

            selectedFiles.forEach(( file ) => {

                formData.append( 'files[]', file )

            })

        }

        try{

            const { data } = await toast.promise(
                axios.post(
                    'https://sistemaintegrado.palmont.com.br/Painel/API/AuditInterna/SaveItem/',
                    formData,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type':'multipart/form-data'
                        }
                    }
                ),
                {
                    pending: 'SALVANDO INFORMAÇÕES',
                    success: 'INFORMAÇÕES SALVAS COM SUCESSO',
                    error: 'FALHA AO SALVAR AS INFORMAÇÕES'
                }
            )

            console.log( data );
            await updateItens();
            close();

        }catch( error ){

            console.log( error );
            toast.error( error.response.data.message );

        }finally{

            setDisabledForm( false );

        }

    }

    return (
        <div className={style.container} >
            <div className={style.containerHeader} >
                <div className={style.closeBox} >
                    <MdOutlineClose
                        className={style.closeIcon}
                        onClick={() => close()}
                    />
                </div>
            </div>
            <div className={style.containerBody} >
                {
                    loading ?
                        <div className={style.loadingContainer} >
                            <div className={ style.loadingBox } >
                                <p className={ style.loadingTxt } >CARREGANDO</p>
                                <div className={ style.spinnerContainer } >
                                    <ImSpinner8 className={ style.spinnerIcon } />
                                </div>
                            </div>
                        </div>
                    :
                        <form
                            className={style.formulario}
                            onSubmit={ handleSubmit }
                        >
                            <FormComp
                                title="AUDITADO"
                                type="select"
                                options={lideres}
                                name="auditado"
                                valor={formValues.auditado}
                                changeFunc={(e) => handleChange(e)}
                                required
                            />

                            {
                                formValues.normas.map((normaItem, index) => (

                                    <div key={index} className={style.normaItem} >
                                        <p className={style.normaItemTitle} >NORMA | ITEM</p>
                                        <FormComp
                                            type="select"
                                            options={[
                                                'ISO 9001:2015',
                                                'ISO 14001:2015',
                                                'ISO 45001:2018'
                                            ]}
                                            name="norma"
                                            valor={normaItem.norma}
                                            changeFunc={(e) => handleChange(e, index, "normas")}
                                            required
                                        />
                                        <FormComp
                                            type="select"
                                            options={getItensByNorma(normaItem.norma)}
                                            name="item"
                                            valor={normaItem.item}
                                            changeFunc={(e) => handleChange(e, index, "normas")}
                                            required
                                        />
                                    </ div>

                                ))
                            }

                            <div className={style.addRmvItens}>
                                <button
                                    type='button'
                                    className={style.addItemBtn}
                                    onClick={addNormaItem}
                                    disabled={formValues.normas.length >= 4}
                                >ADICIONAR ITEM</button>
                                <button
                                    type='button'
                                    className={style.rmvItemBtn}
                                    onClick={removeNormaItem}
                                    disabled={formValues.normas.length <= 1}
                                >REMOVER ITEM</button>
                            </div>


                            <FormComp
                                title="SETOR"
                                type="select"
                                options={setores}
                                name="setor"
                                valor={formValues.setor}
                                changeFunc={(e) => handleChange(e)}
                                required
                            />
                            <FormComp
                                title="CONFORMIDADE"
                                type="select"
                                options={['C', 'NC', 'NA', 'OM']}
                                name="conformidade"
                                valor={formValues.conformidade}
                                changeFunc={(e) => handleChange(e)}
                                required
                            />
                            <FormComp
                                title="EVIDÊNCIA OBJETIVA"
                                type="textarea"
                                name="evidencia"
                                valor={formValues.evidencia}
                                changeFunc={(e) => handleChange(e)}
                                required
                            />
                            {
                                ( formValues.conformidade === 'NC' || formValues.conformidade === 'OM' ) && (
                                    <FormComp
                                        title="FATO"
                                        type="textarea"
                                        name="fato"
                                        valor={ formValues.fato }
                                        changeFunc={(e) => handleChange(e) }
                                        required
                                    />
                                )
                            }
                            <FormComp
                                title="ANEXOS"
                                type="file"
                                multiple
                                changeFunc={ handleFileChange }
                            />
                            <FormComp
                                type="submit"
                                valor={ disabledForm ? 'AGUARDE' : 'SALVAR' }
                                disabled={ disabledForm }
                            />
                        </form>
                }
            </div>
        </div>
    )

}

export default AddNewItem;