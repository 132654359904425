import style from './OverlayView.module.css';

import { useEffect, useState } from 'react';
import axios from 'axios';
import FormComp from '../../FormComp';

import { AiOutlineClose } from "react-icons/ai";

const OverlayView = ({ id, closeRep }) => {

    const [isLoading, setIsLoading] = useState(true);

    const [isEditing, setIsEditing] = useState(false);
    const [requestType, setRequestType] = useState( null );

    const [formQuarto, setFormQuarto] = useState({
        id: null,
        nome: '',
        vagas: '',
        republica: id
    })

    const [republica, setRepublica] = useState([]);
    const [quartos, setQuartos ] = useState([]);

    const searchRep = async () => {

        try {

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/GetRepDetails/', {
                identifier: id
            })

            console.log(data[0])

            setRepublica(data[0])
            setQuartos(data[0].quartos)
            setIsLoading(false)

        } catch (error) {

            console.log(error)

        }

    }

    useEffect(() => {

        searchRep();

    }, [])

    const editQuarto = ({ type }) => {

        setIsEditing( true )
        setRequestType( type )

    }

    const handleChange = ( valor, name ) => {

        let value = valor
        name === 'nome' ? value = valor.toUpperCase() : value = value

        setFormQuarto( prev => ({

            ...prev,
            [ name ] : value

        }))

    }

    const handleSubmit = async ( e ) => {

        e.preventDefault();
        
        try{

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/SaveNewQuarto/', formQuarto )
            console.log( data )

            await searchRep();
            setIsEditing( false )

        }catch( error ){

            console.log( error )

        }

    }

    const [ totalVagas, setTotalVagas ] = useState( 0 )
    const [ vagasPreenchidas, setVagasPreenchidas ] = useState( 0 )

    useEffect(() => {

        if (republica && Array.isArray(republica.quartos)) {

            const valor1 = republica.quartos.reduce((acc, item) => acc + item.vagas, 0);
            const valor2 = republica.quartos.reduce((acc, item) => acc + item.colaboradores.length, 0 );
            
            setTotalVagas( valor1 )
            setVagasPreenchidas( valor2 )

        } else {

            console.log("republica.quartos não é um array válido");

        }

    }, [republica]);

    return (
        <div className={style.container} >
            <div className={style.header} >
                <p className={ style.headerName } >{ republica.apelido_rep }  |  { vagasPreenchidas }/{ totalVagas }</p>
                <div className={style.pdfBox} ></div>
                <div
                    className={style.closeBox}
                    onClick={ () => closeRep() }
                >
                    <AiOutlineClose />
                </div>
            </div>
            <div className={style.body} >
                {
                    isLoading ? (

                        <div className={style.loadingContent} >
                            <div className={style.loadingBox} >
                                <p>CARREGANDO</p>
                            </div>
                        </div>

                    ) : isEditing ? (
                        <div className={ style.formQuartos } >
                            <form onSubmit={ handleSubmit } >
                                <FormComp
                                    title="NOME DO QUARTO"
                                    type="text"
                                    valor={ formQuarto.nome }
                                    required
                                    changeFunc={ ( e ) => handleChange( e.target.value, 'nome' ) }
                                />
                                <FormComp
                                    title="VAGAS"
                                    type="number"
                                    valor={ formQuarto.vagas }
                                    required
                                    changeFunc={ ( e ) => handleChange( e.target.value, 'vagas' )  }
                                />

                                <FormComp
                                    type="submit"
                                    valor="SALVAR"
                                />

                            </form>
                        </div>
                    ) : (

                        <div className={ style.quartosContent } >
                            <div className={ style.titleQuartos } >
                                <p>QUARTOS</p>
                            </div>

                            {
                                quartos.map( obj => (

                                    <div className={ style.quartoBox } >

                                         <div className={ style.quartoInfo } >
                                             <p>{ obj.quarto_name }</p>
                                             <p>-</p>
                                             <p>{ obj.colaboradores.length }/{ obj.vagas } VAGAS</p>
                                         </div>

                                        <div className={ style.quartoContainer } key={ obj.identifier } >
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>MATRICULA</th>
                                                        <th>NOME</th>
                                                        <th>FUNÇÃO</th>
                                                        <th>ENCARREGADO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        obj.colaboradores.map( colab => (

                                                            <tr key={ colab.matricula } >
                                                                <td>{ colab.matricula }</td>
                                                                <td>{ colab.nome }</td>
                                                                <td>{ colab.funcao }</td>
                                                                <td> - </td>
                                                            </tr>

                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>

                                ))
                            }

                            <div className={ style.newRoom } >
                                <button
                                    onClick={ () => editQuarto('create') }
                                > CRIAR NOVO QUARTO </button>
                            </div>

                        </div>

                    )

                }

            </div>
        </div>
    )
}

export default OverlayView;