import axios from 'axios';
import style from './FinishSquare.module.css';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie'
import { useState } from 'react';

const FinishSquare = ({ close, updateAudit }) => {

    const { id } = useParams();
    const token = Cookies.get('token');

    const [ loading, setLoading ] = useState( false );

    const finishAudit = async () => {

        setLoading( true );

        try{

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/AuditInterna/FinishAudit/', {
                id: id
            },{
                headers: {
                    Authorization: `Bearer ${ token }`
                }
            })

            console.log( data )
            await updateAudit();
            close();

        }catch( error ){

            alert( error.response.data.message )
            console.log( error )

        }finally{

            setLoading( false );

        }

    }

    return(
        <div className={ style.container } >
            <p className={ style.txtConfirm } >REALMENTE DESEJA FINALIZAR A AUDITORIA ?</p>
            <div className={ style.boxBtns } >
                <button
                    className={ style.btnConfirm }
                    onClick={ finishAudit }
                    disabled={ loading }
                >{ loading ? "AGUARDE..." : "SIM" }</button>
                <button 
                    className={ style.btnCancel }
                    onClick={ () => close() }
                >NÃO</button>
            </div>
        </div>
    )

}

export default FinishSquare;