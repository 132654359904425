import { useParams } from 'react-router-dom';
import style from './CancelSquare.module.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useState } from 'react';

const CancelSquare = ({ close, updateAudit }) => {

    const { id } = useParams();
    const token = Cookies.get('token')

    const [ motivoCancel, setMotivoCancel ] = useState('')

    const handleChangeMotivo = ( value ) => {

        setMotivoCancel( value )

    }

    const cancelAudit = async () => {

        if( motivoCancel ){

            try{

                const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/AuditInterna/CancelAudit/', {
                    id: id,
                    motivo: motivoCancel
                },{
                    headers: {
                        Authorization: `Bearer ${ token }`
                    }
                })

                await updateAudit();
                close();
    
            }catch( error ){
    
                console.log( error )
    
            }

        }else{

            alert("DESCREVA O MOTIVO DO CANCELAMENTO!!!")

        }

    }

    return(
        <div className={ style.container } >
            <p className={ style.txtConfirm } >REALMENTE DESEJA CANCELAR A AUDITORIA ?</p>
            <div className={ style.motivoArea } >
                <textarea
                    className={ style.motivoTxt }
                    placeholder='DIGITE O MOTIVO DO CANCELAMENTO...'
                    value={ motivoCancel }
                    onChange={ ( e ) => handleChangeMotivo( e.target.value ) }
                ></textarea>
            </div>
            <div className={ style.btnsBox } >
                <button
                    className={ style.btnConfirm }
                    onClick={ cancelAudit }
                >SIM</button>
                <button
                    className={ style.btnCancel }
                    onClick={() => { close() }}
                >NÃO</button>
            </div>
        </div>
    )

}

export default CancelSquare;