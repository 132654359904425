import { format, parse } from 'date-fns';

export function formatarData(dateString, includeTime = false) {
    if (!dateString) {
        throw new Error('A data é obrigatória.');
    }

    let dateObject;
    
    // Tentar identificar e converter o formato
    if (dateString.length === 10) { // yyyy-MM-dd ou yyyyMMdd
        // Tentar o formato yyyy-MM-dd
        try {
            dateObject = parse(dateString, 'yyyy-MM-dd', new Date());
        } catch (error) {
            // Caso não consiga, tenta o formato yyyyMMdd
            if (dateString.length === 8) {
                dateObject = parse(dateString, 'yyyyMMdd', new Date());
            } else {
                throw new Error('Formato de data inválido. Esperado: yyyy-MM-dd ou yyyyMMdd.');
            }
        }
    } else if (dateString.length === 19) { // yyyy-MM-dd HH:mm:ss
        dateObject = parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date());
    } else {
        throw new Error('Formato de data inválido. Esperado: yyyy-MM-dd, yyyy-MM-dd HH:mm:ss ou yyyyMMdd.');
    }

    // Verifica se a data foi corretamente parseada
    if (isNaN(dateObject.getTime())) {
        throw new Error('Data inválida.');
    }

    // Formatação dependendo se a hora precisa ser incluída
    if (includeTime) {
        return format(dateObject, 'dd/MM/yyyy HH:mm');
    } else {
        return format(dateObject, 'dd/MM/yyyy');
    }
}