import style from './ViewEdits.module.css';

import axios from 'axios';

import { formatarData } from '../../../Funcoes/FormatarData'
import { RiLoader4Fill } from "react-icons/ri";
import { MdOutlineClose } from "react-icons/md";
import { useEffect, useState } from 'react';

const ViewEdits = ({ closeFunc, id }) => {

    const [loading, setLoading] = useState(true)

    const [values, setValues] = useState({})

    const searchModifies = async () => {

        try{

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/AuditInterna/GetAudit/', {
                id: id
            })

            console.log( data )
            setValues( data )

        }catch( error ){

            console.log( error )

        }finally{

            setLoading( false );

        }

    }

    useEffect(() => {

        searchModifies();

    }, [ id ])

    return (
        <div className={style.container} >
            <div className={style.header} >
                <div
                    className={style.closeBox}
                    onClick={() => closeFunc()}
                >
                    <MdOutlineClose
                        className={ style.closeIcon }
                    />
                </div>
            </div>
            <div className={style.body} >
                {
                    loading ?
                        <div className={ style.loadingDiv }>
                            <div className={ style.loadingDivTxt }>
                                <p>CARREGANDO</p>
                            </div>
                            <div className={ style.loadingDivIcon }>
                                <RiLoader4Fill />
                            </div>

                        </div>
                        :
                        <div>
                            {
                                values.reprogramada && (
                                    <div className={ style.editedDiv } >
                                        <p className={ style.editedTitle } >AUDITORIA REPROGRAMADA</p>
                                        <textarea
                                            className={ style.editedValue }
                                            value={ values.reprogramada.motivo }
                                            readOnly
                                        />
                                        <p className={ style.editedByTxt } >{ values.reprogramada.user } | { formatarData( values.reprogramada.data, true ) }</p>
                                    </div>
                                )
                            }
                            {
                                values.cancelado && (
                                    <div className={ style.editedDiv } >
                                        <p className={ style.editedTitle } >AUDITORIA CANCELADA</p>
                                        <textarea
                                            className={ style.editedValue }
                                            value={ values.cancelado.motivo }
                                            readOnly
                                        />
                                        <p className={ style.editedByTxt } >{ values.cancelado.user } | { formatarData( values.cancelado.data, true ) }</p>
                                    </div>
                                )
                            }
                        </div>
                }
            </div>
        </div>
    )

}

export default ViewEdits;