import { useState } from 'react';
import FormComp from '../../FormComp';
import style from './ReprogForm.module.css';

import { IoMdClose } from "react-icons/io";
import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const ReprogForm = ({ updateItens, closeForm, id }) => {

    const token = Cookies.get('token');

    const [ formValues, setFormValues ] = useState({
        nova_data: "",
        motivo: "",
        id: id
    })

    const handleChange = ( valor, name ) => {

        setFormValues( prev => ({
            ...prev,
            [name]: valor
        }))

    }

    const handleSubmit = async ( e ) => {

        e.preventDefault();
        
        try{

            await toast.promise(
                axios.post(
                    'https://sistemaintegrado.palmont.com.br/Painel/API/AuditInterna/ReprogAudit/',
                    formValues,
                    {
                        headers:{
                            'Authorization': `Bearer ${ token }`
                        }
                    }
                ),
                {
                    pending: 'SALVANDO INFORMAÇÕES',
                    success: 'INFORMAÇÕES SALVAS COM SUCESSO',
                    error: 'FALHA AO SALVAR AS INFORMAÇÕES'
                }
            )

            await updateItens();
            closeForm();

        }catch( error ){

            console.error( error )
            toast.error( error.response.data.error );

        }

    }

    return(
        <div className={ style.container } >
            <div className={ style.header } >
                <div className={ style.closeIconBox } >
                    <IoMdClose
                        className={ style.closeIcon }
                        onClick={ () => { closeForm() } }
                    />
                </div>
            </div>
            <div className={ style.bodyForm } >
                <form className={ style.formluario } onSubmit={ handleSubmit }  >
                    <FormComp
                        title="NOVA DATA DE ÍNICIO"
                        type="date"
                        valor={ formValues.nova_data }
                        changeFunc={ ( e ) => handleChange( e.target.value, 'nova_data' ) }
                        required
                    />
                    <FormComp
                        title="MOTIVO DA REPROGRAMAÇÃO"
                        type="textarea"
                        valor={ formValues.motivo }
                        changeFunc={ ( e ) => handleChange( e.target.value, 'motivo' ) }
                        required
                    />
                    <FormComp
                        type="submit"
                        valor="SALVAR"
                    />
                </form>
            </div>
        </div>
    )

}

export default ReprogForm;