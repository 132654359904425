export const getItensByNorma = ( norma ) => {

    const itensNorma = {
        "ISO 9001:2015" : [
            "4.1 - Entendendo a organização e seu contexto",
            "4.2 - Entendendo as necessidades e expectativas de partes interessadas",
            "4.3 - Determinando o escopo do sistema de gestão de qualidade",
            "4.4 - Sistema de gestão da qualidade e seus processos",
            "5.1 - Liderança e comprometimento",
            "5.1.1 - Generalidades",
            "5.1.2 - Foco no cliente",
            "5.2 - Política",
            "5.2.1 - Desenvolvendo a política da qualidade",
            "5.2.2 - Comunicando a poítica da qualidade",
            "5.3 - Papéis, responsabilidades e autoridades organizacionais",
            "6.1 - Ações para abordar riscos e oportunidades",
            "6.1.1 - Generalidades",
            "6.1.2 - Aspectos da qualidade",
            "6.2 - Objetivos da qualidade e planejamento para alcançá-los",
            "6.2.1 - Objetivos da qualidade",
            "6.2.2 - Planejamento de ações para alcaçar os objetivos da qualidade",
            "6.3 - Planejamento de mudanças",
            "7.1 - Recursos",
            "7.1.1 - Generalidades",
            "7.1.2 - Pessoas",
            "7.1.3 - Infraestrutura",
            "7.1.4 - Ambiente para a operação dos processos",
            "7.1.5 - Recursos de monitoramento e medição",
            "7.1.5.1 - Generalidades",
            "7.1.5.2 - Rastreabilidade de Medição",
            "7.1.6 - Conhecimento organizacional",
            "7.2 - Competência",
            "7.3 - Conscientização",
            "7.4 - Comunicação",
            "7.5 - Informação documentada",
            "7.5.1 - Generalidades",
            "7.5.2 - Criando atualizando",
            "7.5.3 - Controle de informação documentada",
            "8.1 - Planejamento e controle operacionais",
            "8.2 - Requisitos para produtos e serviços",
            "8.2.1 - Comunicação com o cliente",
            "8.2.2 - Determinação de requisitos relativos a produtos e serviços",
            "8.2.3 - Análise crítica de requisitos relativos a produtos e serviços",
            "8.2.4 - Mudanças nos requisitos para produtos e serviços",
            "8.4 - Controle de processos, produtos e serviços providos externamente",
            "8.4.1 - Generalidades",
            "8.4.2 - Tipo e extensão do controle",
            "8.4.3 - Informação para provedores externos",
            "8.5 - Produção e provisão de serviço",
            "8.5.2 - Identificação e rastreabilidade",
            "8.5.3 - Propriedade pertencente a clientes ou provedores externos",
            "8.5.4 - Preservação",
            "8.5.5 - Atividades pós-entrega",
            "8.5.6 - Controle de mudanças",
            "8.6 - Liberação de produtos e serviços",
            "8.7 - Controle de saídas não conformes",
            "9.1 - Monitoramento, medição, análise e avaliação",
            "9.1.1 - Generalidades",
            "9.1.2 - Satisfação do cliente",
            "9.1.3 - Análise e avaliação",
            "9.2 - Auditoria interna",
            "9.2.1 - Generalidades",
            "9.2.2 - Programa de auditoria interna",
            "9.3 - Análise crítica pela direção",
            "9.3.1 - Generalidades",
            "9.3.2 - Entradas de análise crítica pela direção",
            "9.3.3 - Saídas de análise crítica pela direção",
            "10.1 - Generalidades",
            "10.2 - Não conformidade e ação corretiva",
            "10.3 - Melhoria contínua"
        ],
        "ISO 14001:2015" : [
            "4.1 - Entendendo a organização e seu contexto",
            "4.2 - Entendendo as necessidades e expectativas de partes interessadas",
            "4.3 - Determinando o escopo do sistema de gestão de ambiental",
            "4.4 - Sistema de gestão ambiental",
            "5.1 - Liderança e comprometimento",
            "5.2 - Política Ambiental",
            "5.3 - Papéis, responsabilidades e autoridades organizacionais",
            "6.1 - Ações para abordar riscos e oportunidades",
            "6.1.1 - Generalidades",
            "6.1.2 - Aspectos ambientais",
            "6.1.3 - Requisitos legais e outros requisitos",
            "6.1.4 - Planejamentos e ações",
            "6.2 - Objetivos ambientais e planejamento para alcançá-los",
            "6.2.1 - Objetivos ambientais",
            "6.2.2 - Panejamento de ações para alcançar os objetivos ambientais",
            "7.1 - Recursos",
            "7.2 - Competência",
            "7.3 - Conscientização",
            "7.4 - Comunicação",
            "7.4.1 - Generalidades",
            "7.4.2 - Comunicação interna",
            "7.4.3 - Comunicação externa",
            "7.5 - Informação documentada",
            "7.5.1 - Generalidades",
            "7.5.2 - Criando atualizando",
            "7.5.3 - Controle de informação documentada",
            "8.1 - Planejamento e controle operacionais",
            "8.2 - Preparação e resposta a emergências",
            "9.1 - Monitoramento, medição, análise e avaliação",
            "9.1.1 - Generalidades",
            "9.1.2 - Avaliação do atendimento aos requisitos legais e outros requisitos",
            "9.2 - Auditoria interna",
            "9.2.1 - Generalidades",
            "9.2.2 - Programa de auditoria interna",
            "9.3 - Análise crítica pela direção",
            "10.1 - Generalidades",
            "10.2 - Não conformidade e ação corretiva",
            "10.3 - Melhoria contínua"
        ],
        "ISO 45001:2018" : [
            "4.1 - Entendendo a organização e seu contexto",
            "4.2 - Entendendo as necessidades e expectativas dos trabalhadores e de outras partes interessadas",
            "4.3 - Determinando o escopo do sistema de SST",
            "4.4 - Sistema de gestão da SST",
            "5.1 - Liderança e comprometimento",
            "5.2 - Política de SST",
            "5.3 - Papéis, responsabilidades e autoridades organizacionais",
            "5.4 - Consulta e participação dos trabalhadores",
            "6.1 - Ações para abordar riscos e oportunidades",
            "6.1.1 - Generalidades",
            "6.1.2 - Identificação de perigos e avaliação de riscos e oportunidades",
            "6.1.2.1 - Identificação de perigos",
            "6.1.2.2 - Avaliação de riscos de SST e outos riscos para o sistema de gestão da SST",
            "6.1.2.3 - Avaliação de oportunidades de SST e outras oportunidades para o sistema de gestão da SST",
            "6.1.3 - Requisitos legais e outros requisitos",
            "6.1.4 - Planejamentos e ações",
            "6.2 - Objetivos de SST e planejamento para alcançá-los",
            "6.2.1 - Objetivos de SST",
            "6.2.2 - Planejamento de ações de ações para alcançar os objetivos de SST",
            "7.1 - Recursos",
            "7.2 - Competência",
            "7.3 - Conscientização",
            "7.4 - Comunicação",
            "7.4.1 - Generalidades",
            "7.4.2 - Comunicação interna",
            "7.4.3 - Comunicação externa",
            "7.5 - Informação documentada",
            "7.5.1 - Generalidades",
            "7.5.2 - Criando atualizando",
            "7.5.3 - Controle de informação documentada",
            "8.1 - Planejamento e controle operacionais",
            "8.1.1 - Generalidades",
            "8.1.2 - Eliminando Perigos e Riscos",
            "8.1.3 - Gestão de mudanças",
            "8.1.4 - Aquisição",
            "8.1.4.1 - Generalidades",
            "8.1.4.2 - Contratados",
            "8.1.4.3 - Terceirização",
            "8.2 - Preparação e resposta a emergências",
            "9.1 - Monitoramento, medição, análise e avaliação",
            "9.1.1 - Generalidades",
            "9.1.2 - Avaliação do atendimento aos requisitos legais e outros requisitos",
            "9.2 - Auditoria interna",
            "9.2.1 - Generalidades",
            "9.2.2 - Programa de auditoria interna",
            "9.3 - Análise crítica pela direção",
            "10.1 - Generalidades",
            "10.2 - Incidente, Não conformidade e ação corretiva",
            "10.3 - Melhoria contínua"
        ]
    }

    return itensNorma[ norma ] || []

}