import { formatarData } from '../../../Funcoes/FormatarData';
import style from './PlanejamentoBody.module.css';

const PlanejamentoBody = ({ planejamento, editItem }) => {

    return(
        <div className={ style.container } >
            <div className={ style.square } >
                <table>
                    <thead>
                        <tr>
                            <th>PLANEJADO POR</th>
                            <th>AUDITOR</th>
                            <th>AUDITADO</th>
                            <th>SETOR</th>
                            <th>DATA PLANEJADA</th>
                            <th>DATA REALIZADA</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            planejamento && planejamento.map( obj => (
                                <tr
                                    key={ obj.id }
                                    onClick={ () => editItem( obj.id ) }
                                >
                                    <td>{ obj.user }</td>
                                    <td>{ obj.auditor }</td>
                                    <td>{ obj.auditado }</td>
                                    <td>{ obj.setor }</td>
                                    <td>{ formatarData( obj.data_planej, true ) }</td>
                                    <td>{ obj.ultima_data ? formatarData( obj.ultima_data, true ) : '-' }</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )

}

export default PlanejamentoBody;