import { useEffect, useState } from 'react';
import style from './EditItem.module.css';
import axios from 'axios';
import FormComp from '../../FormComp';

import { GoTrash } from "react-icons/go";
import { getItensByNorma } from '../../../Funcoes/GetItensByNorma';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const EditItem = ({ auditoria, itemSelected, closeEdit, updateItens }) => {

    const token = Cookies.get('token');

    const [loading, setLoading] = useState(true);
    const [itemInfo, setItemInfo] = useState({});

    const [lideres, setLideres] = useState([])
    const setores = [
        "ADM",
        "ALMOXARIFADO/MATERIAIS",
        "ALTA DIREÇÃO",
        "ANDAIMES",
        "COMERCIAL",
        "CONTABILIDADE",
        "CONTROLE DE OBRAS",
        "ELÉTRICA",
        "ENGENHARIA E ORÇAMENTOS",
        "FABRICAÇÃO (PIPE SHOP)",
        "FINANCEIRO",
        "FISCAL",
        "IÇAMENTO E MOVIMENTAÇÃO DE CARGA",
        "INSTRUMENTAÇÃO",
        "ISOLAMENTO TÉRMICO",
        "JURÍDICO",
        "LOGÍSTICA",
        "MANUTENÇÃO MECÂNICA",
        "MECÂNICA",
        "PINTURA",
        "PLANEJAMENTO/GERENCIAMENTO DE OBRAS",
        "QUALIDADE",
        "RECURSOS HUMANOS",
        "SGI - SISTEMA DE GESTÃO INTEGRADO",
        "SSMA",
        "SUPRIMENTOS",
        "T.I.",
        "TUBULAÇÃO"
    ]
    const [itens, setItens] = useState([])
    const [selectdFiles, setSelectedFiles] = useState([])

    useEffect(() => {

        if (itemInfo.norma) {

            const dados = getItensByNorma(itemInfo.norma)
            setItens(dados)

        } else {

            setItens([])

        }

    }, [itemInfo.norma])

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);

            try {

                const fetchItem = async () => {

                    const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/AuditInterna/GetItemInfo/', {
                        id: itemSelected
                    })
                    setItemInfo(data);
                    console.log(data);

                }

                const getLideres = async () => {

                    const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/assets/php/getLideres.php');
                    setLideres(data.map(obj => obj.RA_NOMECMP));

                }

                await Promise.all([fetchItem(), getLideres()])

            } catch (error) {

                console.log(error);

            } finally {

                setLoading(false);

            }

        }

        if (itemSelected) {

            fetchData();

        }

    }, [itemSelected])

    const getFileName = (path) => {

        const file = path.split('/').pop();
        return file;

    }

    const handleChange = (e) => {

        const { name, value } = e.target;

        if (name === 'norma') {

            setItemInfo({
                ...itemInfo,
                subitem: '',
                [name]: value
            })

        } else if (name === 'conformidade') {

            setItemInfo({
                ...itemInfo,
                fato: '',
                [name]: value
            })

        } else {

            setItemInfo({
                ...itemInfo,
                [name]: value
            })

        }

    }

    const handleFileChange = ( e ) => {

        const files = e.target.files
        const arrayFiles = Array.from( files )

        setSelectedFiles( arrayFiles )

    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        console.log( itemInfo );

        const formData = new FormData();

        formData.append( 'id', itemInfo.id )
        formData.append( 'auditado', itemInfo.auditado )
        formData.append( 'norma', itemInfo.norma )
        formData.append( 'item', itemInfo.subitem )
        formData.append( 'setor', itemInfo.setor )
        formData.append( 'conformidade', itemInfo.conformidade )
        formData.append( 'evidencia', itemInfo.evidencia )
        formData.append( 'fato', itemInfo.fato )
        formData.append( 'grupo', itemInfo.grupo )

        if( selectdFiles ){

            selectdFiles.forEach(( file ) => {

                formData.append( 'files[]', file )

            })

        }
        
        try{

            const { data } = await toast.promise(
                axios.post(
                    'https://sistemaintegrado.palmont.com.br/Painel/API/AuditInterna/EditItem/',
                    formData,
                    {
                        headers: {
                            "Authorization": `Bearer ${ token }`,
                            "Content-Type": "multipart/form-data"
                        }
                    }
                ),
                {
                    pending: 'SALVANDO INFORMAÇÕES',
                    success: 'INFORMAÇÕES SALVAS COM SUCESSO',
                    error: 'FALHA AO SALVAR AS INFORMAÇÕES'
                }
            )

            console.log( data )
            await updateItens();
            closeEdit();

        }catch( error ){

            console.log( error );
            toast.error( error.response.data.message );

        }

    }

    return (
        <div className={style.container} >
            <div className={style.header} >
                <div
                    className={style.closeBox}
                    onClick={() => { closeEdit() }}
                ></div>
            </div>
            <div className={style.containerBody} >
                {
                    loading ?
                        <div className={style.loadContainer} >
                            <p>CARREGANDO...</p>
                        </div>
                        :
                        <form
                            className={style.formulario}
                            onSubmit={ handleSubmit }
                        >
                            <FormComp
                                title="AUDITADO"
                                type="select"
                                options={lideres}
                                valor={itemInfo.auditado}
                                changeFunc={(e) => handleChange(e)}
                                name="auditado"
                            />
                            <FormComp
                                title="NORMA"
                                type="select"
                                options={[
                                    "ISO 9001:2015",
                                    "ISO 14001:2015",
                                    "ISO 45001:2018"
                                ]}
                                valor={itemInfo.norma}
                                changeFunc={(e) => handleChange(e)}
                                name="norma"
                            />
                            <FormComp
                                title="ITEM"
                                type="select"
                                options={ itens }
                                valor={itemInfo.subitem}
                                changeFunc={(e) => handleChange(e)}
                                name="subitem"
                            />
                            <FormComp
                                title="SETOR"
                                type="select"
                                options={ setores }
                                valor={itemInfo.setor}
                                changeFunc={(e) => handleChange(e)}
                                name="setor"
                            />
                            <FormComp
                                title="CONFORMIDADE"
                                type="select"
                                options={[
                                    "C",
                                    "NC",
                                    "NA",
                                    "OM"
                                ]}
                                valor={itemInfo.conformidade}
                                changeFunc={(e) => handleChange(e)}
                                name="conformidade"
                            />
                            <FormComp
                                title="EVIDÊNCIA OBJETIVA"
                                type="textarea"
                                valor={itemInfo.evidencia}
                                changeFunc={(e) => handleChange(e)}
                                name="evidencia"
                            />

                            {
                                (itemInfo.conformidade === "NC" || itemInfo.conformidade === "OM") && (
                                    <FormComp
                                        title="FATO"
                                        type="textarea"
                                        valor={itemInfo.fato}
                                        changeFunc={(e) => handleChange(e)}
                                        name="fato"
                                    />
                                )
                            }

                            <div className={style.anexos} >
                                <p className={style.anexosTitle} >ANEXOS</p>
                                {
                                    itemInfo.anexos?.length >= 1 ?
                                        (
                                            <div className={style.anexosList} >
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>NOME</th>
                                                            <th>EXCLUIR</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            itemInfo.anexos.map((anexo, index) => (
                                                                <tr key={index} >
                                                                    <td> <a href={anexo} > {getFileName(anexo)} </a></td>
                                                                    <td>
                                                                        <GoTrash size={15}
                                                                            onClick={() => {
                                                                                // add function to remove anexo
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <div>
                                                <p>NENHUM ANEXO</p>
                                            </div>
                                        )
                                }
                            </div>

                            <FormComp
                                title="ADICIONAR ANEXOS"
                                type="file"
                                multiple
                                changeFunc={ handleFileChange }
                            />
                            <FormComp
                                type="submit"
                                valor="SALVAR"
                            />
                        </form>
                }
            </div>
        </div>
    )

}

export default EditItem;