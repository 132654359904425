import { useCallback, useEffect, useState } from 'react';
import style from './CheckItensBody.module.css';
import axios from 'axios';

import { useParams } from 'react-router-dom';
import Select, { components } from 'react-select';

import { MdOutlineCheck, MdOutlineClose } from "react-icons/md";
import { ImSpinner8 } from "react-icons/im";
import { getItensByNorma } from "../../../Funcoes/GetItensByNorma/"


const CheckItensBody = () => {

    const { id } = useParams();

    const [loading, setLoading] = useState(true);

    const [itens, setItens] = useState([])
    const [itensFiltered, setItensFiltered] = useState([])

    const fetchItens = async () => {

        setLoading(true);

        try {

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/AuditInterna/GetCheckedItens/', {
                identifier: id
            })

            setItens(data);
            setItensFiltered(data);

        } catch (error) {

            console.log(error)

        } finally {

            setLoading(false);

        }

    }

    useEffect(() => {

        fetchItens();

    }, [])

    const ValueContainer = ({ children, ...props }) => {

        let [ values, input ] = children
        if( Array.isArray( values )){

            const plural = values.length === 1 ? "" : "S";
            values = `${ values.length } SELECIONADO${ plural }`

        }

        return(

            <components.ValueContainer { ...props } >
                { values }
                { input }
            </components.ValueContainer>

        )

    }

    const [ normas, setNormas ] = useState([])

    const [ itensFilter, setItensFilter ] = useState([])
    const [ selectedItens, setSelectedItens ] = useState([])

    const [ statusItens, setStatusItens ] = useState( null )

    useEffect(() => {

        const normaValues = normas.map(obj => obj.value);
    
        const itensList = Array.from(

            new Set(
                normaValues.flatMap(norma => {
                    const itensRetornados = getItensByNorma(norma) || [];
                    return itensRetornados;
                })
            )
        ).sort((a, b) => a.localeCompare(b));
    
        setItensFilter(itensList);
        setSelectedItens([])

    }, [normas]);

    const handleFilter = useCallback(( normaFilter, itemFilter, statusFilter ) => {

        const filtered = itens.filter( item => {

            const norma = normaFilter.length === 0 || normaFilter.some( obj => item.NORMA.includes( obj.value ))
            const itemToFilter = itemFilter.length === 0 || itemFilter.some( obj => item.ITEM.includes( obj.value ))
            const statusToFilter = 
                !statusFilter ||
                ( statusFilter.value === "PENDENTE" && item.PREENCHIDO !== "*" ) ||
                ( statusFilter.value === "PREENCHIDO" && item.PREENCHIDO === "*" );

            return norma && itemToFilter && statusToFilter;

        })

        setItensFiltered( filtered )

    }, [ itens ])

    useEffect(() => {

        handleFilter( normas, selectedItens, statusItens )

    }, [ normas, selectedItens, statusItens, fetchItens ])

    return (
        <div className={style.container} >
            <div className={style.header} >
                <div className={style.filtersContainer} >
                    <div className={style.filtersChild} >
                        <Select
                            className={style.customSelect}
                            placeholder="NORMA..."
                            options={[
                                { value: 'ISO 9001:2015', label: 'ISO 9001:2015' },
                                { value: 'ISO 14001:2015', label: 'ISO 14001:2015' },
                                { value: 'ISO 45001:2018', label: 'ISO 45001:2018' }
                            ]}
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            value={ normas }
                            onChange={ ( selected ) => { setNormas( selected ) } }
                        />
                    </div>
                    <div className={style.filtersChild} >
                        <Select
                            className={style.customSelect}
                            placeholder="ITEM..."
                            options={itensFilter.map((item) => (
                                { value: item, label: item }
                            ))}
                            value={ selectedItens }
                            onChange={ ( selected ) => { setSelectedItens( selected ) } }
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                        />
                    </div>
                    <div className={style.filtersChild} >
                        <Select
                            className={style.customSelect}
                            placeholder="STATUS..."
                            isClearable
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            options={[
                                { value: "PREENCHIDO", label: "PREENCHIDO" },
                                { value: "PENDENTE", label: "PENDENTE" }
                            ]}
                            components={{ ValueContainer }}
                            value={ statusItens }
                            onChange={ ( selected ) => { setStatusItens( selected ) } }
                        />
                    </div>
                </div>
                <div className={style.statsContainer} >
                    <div className={style.statsBox} >
                        <p>PREENCHIDOS: { itensFiltered.filter( obj => obj.PREENCHIDO === '*' ).length }</p>
                        <p>PENDENTES: { itensFiltered.filter( obj => obj.PREENCHIDO !== "*" ).length }</p>
                    </div>
                    <div className={style.updateBtnDiv} >
                        <button
                            onClick={ () => { fetchItens() } }
                        >ATUALIZAR</button>
                    </div>
                </div>
            </div>
            <div className={style.body} >
                {
                    loading ? (
                        <div className={style.loadingDiv} >
                            <div className={style.loadingCont} >
                                <div className={style.textLoad} >
                                    <p>CARREGANDO</p>
                                </div>
                                <div>
                                    <ImSpinner8 className={style.iconLoad} size={18} />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>NORMA</th>
                                    <th>ITEM</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    itensFiltered.map((obj, index) =>
                                    (
                                        <tr key={index} >
                                            <td>
                                                <div className={style.iconTable} >
                                                    {obj.PREENCHIDO === '*' ? (
                                                        <MdOutlineCheck
                                                            className={style.checkIcon}
                                                        />
                                                    ) : (
                                                        <MdOutlineClose
                                                            className={style.xIcon}
                                                        />
                                                    )}
                                                </div>
                                            </td>
                                            <td>{obj.NORMA}</td>
                                            <td className={style.itemColumn} >{obj.ITEM}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    )
                }

            </div>
        </div>
    )

}

export default CheckItensBody;